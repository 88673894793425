<template>
    <div :class="['breadcrumb',isSticky ? 'breadcrumb-sticky': '',background ? 'bg' : '',bgWhite ? 'bg-white' : '']">
        <div class="container-fluid breadcrumb-content">
            <button ref="btn" v-if="backButton" @click="goToBack" class="btn btn-back btn-icon btn-round">
                <span class="material-icons-outlined show-ltr" aria-hidden="true">arrow_back</span>
                <span class="material-icons-outlined show-rtl" aria-hidden="true">arrow_forward</span>        
            </button>

            <h4 v-if="content">{{ content }}</h4>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumb',
        props: {
            background: {
                type: Boolean,
                default: false,
            },
            content: {
                type: String,
                default: ''
            },
            backButton: {
                type: Boolean,
                default: true,
            },
            bgWhite: {
                type: Boolean,
                default: false,
            },
            isSticky: {
                type: Boolean,
                default: false,
            },
            backFocus: {
                type: Boolean, 
                default: false,
            },
        },
        methods: {
            goToBack() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
            }
        },
        mounted() {
            if(this.backFocus == true) {
                setTimeout(() => {
                    this.$refs.btn.focus();
                }, 2000);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';
    @import '@/scss/_mixins.scss';

    .breadcrumb {
        position: absolute;
        background: transparent;
        padding: 0;
        height: 64px;
        width: 100vw;
        margin-bottom: 0;
        z-index: 1001;
        top: 0;
        border-radius: 0;

        @include responsive($breakpoint) {
            position: relative;
            background-color: #F9F9F9;
            height: 70px;
            z-index: inherit;
        }

        &-sticky {
            position: fixed;

            @include responsive($breakpoint) {
                position: relative;
            }
        }

        &.bg {
            position: relative;
            background-color: $primary;

            @include responsive($breakpoint) {
                background: #F0F0F8;
            }
        }

        &.bg-white {
            background-color: $white-color;
        }

        .btn-back{
            width: 40px!important;
            height: 40px!important;
            min-width: 40px !important;
            min-height: 40px !important;
        }

        &-content {
            display: flex;
            align-items: center;

            h4 {
                color: $white-color;
                margin-left: 15px;

                @include responsive($breakpoint) {
                    color: $text-color;
                }

                @media (max-width: 340px) {
                    font-size: 15px;
                }
            }
        }
    }

    .btn-round:focus-visible {
        border-radius: 50% !important;
    }

    body[dir="rtl"] {
        .breadcrumb{
            .breadcrumb-content {
                h4 {
                    margin-right: 15px;
                    margin-left: 0;
                }
            }
        }
    }
</style>
